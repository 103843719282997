






















import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'

import DetectSafariMixin from '@/mixins/DetectSafariMixin'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import DictionaryModule from '@/store/modules/dictionary'
import MentorEducationModule from '@/store/modules/mentor/education'
import { EducationMasterGroupResource } from '@/store/types'

@Component
export default class MasterGroupsSearch extends Mixins(DetectSafariMixin, MasterGroupMixin) {
  @Prop({ required: true })
  private value!: number

  @Prop( { default: false })
  private returnObject!: boolean

  @Prop({ default: false })
  private onlySelf!: boolean

  @Prop({ default: false })
  private invalid!: boolean

  @Prop({ default: '' })
  private error!: string

  @Prop({
    default: 'default',
    validator (value: string): boolean {
      return !!value.match(/(default|orange)/)
    },
  })
  private skin!: 'default' | 'orange'

  private innerValue = 0
  private search = ''
  private isLoading = false

  private mounted() {
    if (this.value) {
      this.innerValue = this.value
    }
  }

  private get masterGroupsSelect() {
    return DictionaryModule.masterGroupsSelect.map(group => ({ name: group.title, value: group.id }))
  }

  private get mentorMasterGroups() {
    return MentorEducationModule.masterGroups
  }

  @Bind
  @Debounce(500)
  private fetchMasterGroups(query: string) {
    if (this.isLoading) return

    this.isLoading = true
    return this.fetchMasterGroupsSelect({ query, self: this.onlySelf ? this.onlySelf : undefined })
      .then((masterGroups) => {
        // Note: случай со всеми мастер-группами
        // Может быть полезно, если по какой-то причине нет ни одной мастер группы в селекте
        // в таком случае мы можем привязать какую-либо логику на этот кейс
        if (query === null && masterGroups && masterGroups.length === 0) {
          this.$emit('callbackForDestroyCase', masterGroups)
        }
      })
      .catch(this.notifyError)
      .finally(() => this.isLoading = false)
  }

  private handleSelect(value: number) {
    if (!this.returnObject) {
      if (this.innerValue) {
        this.$emit('update:value', value)
        this.$emit('input', value)

        // ToDo Нужно отойти будет от добавления новой группы
        if (!this.mentorMasterGroups.some(item => item.id == value)) {
          const missingGroup = DictionaryModule.masterGroupsSelect.find(item => item.id === value)
          MentorEducationModule.setMasterGroups([...this.mentorMasterGroups, missingGroup as EducationMasterGroupResource])
        }
      } else {
        this.$emit('update:value', this.currentMasterGroupID)
        this.$emit('input', this.currentMasterGroupID)
      }
    }
  }

  private handleChangingSearch() {
    if (this.innerValue === null) {
      this.innerValue = this.currentMasterGroupID
    }
  }

  @Watch('search')
  private watchSearch (value: string, oldValue: string) {
    if (value !== oldValue) {
      this.fetchMasterGroups(value)
    }
  }
}
