import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import {
  ManagerDashboardMasterGroupIdGetRequest,
  ManagerDashboardResource,
} from '@/store/types'

/**
 * Главная страница менеджера
 *
 */

@Module({
  dynamic: true,
  name: 'ManagerDashboardModule',
  namespaced: true,
  store,
})
class ManagerDashboard extends VuexModule {
  // ---------------------------- Dashboard Manager ---------------------------- >>
  dashboard: ManagerDashboardResource | null = null

  @Mutation
  setDashboard(payload: ManagerDashboardResource) {
    this.dashboard = payload
  }

  @Action({ rawError: true })
  async fetchDashboard(courseId: number) {
    const { data } = await ManagerDashboardMasterGroupIdGetRequest(courseId)
    this.setDashboard(data)
    return data
  }

}

const ManagerDashboardModule = getModule(ManagerDashboard)

export default ManagerDashboardModule
