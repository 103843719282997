


































































import { Component, Prop } from 'vue-property-decorator'

import NotifyMixin from '@/mixins/NotifyMixin'
import { LessonShortResource, NameValueResource } from '@/store/types'
import ButtonIconAction from '@/components/_uikit/buttons/ButtonIconAction.vue'
import { declension, parseDateToMilliseconds } from '@/utils/functions'
import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'
import Tag from '@/components/_uikit/Tag.vue'
import AuthModule from '@/store/modules/auth'
@Component({

  components: {
    ButtonArrow,
    ButtonIconAction,
    Tag,
  },
})
export default class ManagerDashboardWebinar extends NotifyMixin {
  @Prop({ default: () => ([]) })
  private webinars!: LessonShortResource[]

  private currentSpendingTime: NameValueResource[] = []
  private currentWebinarIndex = 0
  private days = 0
  private hours = 0
  private minutes = 0
  private seconds = 0

  private get amount () {
    return this.webinars.length
  }

  private get webinar(){
    this.currentSpendingTime = this.spendingTime(this.webinars[this.currentWebinarIndex])
    return this.webinars[this.currentWebinarIndex]
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private spendingTime(lesson: LessonShortResource): NameValueResource[] {
    const deadline = parseDateToMilliseconds(lesson.spendingAt)
    const secondsTill = (deadline - Date.now()) / 1000
    let result: NameValueResource[] = []

    this.days = Math.floor((secondsTill / 86400))
    this.hours = Math.floor((secondsTill - this.days * 86400) / 3600)
    this.minutes = Math.floor((secondsTill - (this.hours * 3600 + this.days * 86400) ) / 60)
    this.seconds = Math.floor(secondsTill - (this.minutes * 60 + this.hours * 3600 + this.days * 86400))

    if (this.days < 0) {
      return result
    }

    if (this.days > 0) {
      result = [
        { name: declension(this.days, ['день', 'дня', 'дней']), value: this.days },
        { name: declension(this.hours, ['час', 'часа', 'часов']), value: this.hours },
      ]
    }

    else if (this.hours > 0) {
      result = [
        { name: declension(this.hours, ['час', 'часа', 'часов']), value: this.hours },
        { name: 'мин', value: this.minutes },
      ]
    }

    else if (this.minutes >= 0) {
      result = [
        { name: 'мин', value: this.minutes },
        { name: 'сек', value: this.seconds },
      ]
    }

    return result
  }

  private handlePrevious() {
    if (this.currentWebinarIndex !== 0) {
      this.currentWebinarIndex -= 1
    }
  }

  private handleNext() {
    if (this.currentWebinarIndex !== this.amount - 1) {
      this.currentWebinarIndex += 1
    }
  }

  private get isNextButtonShow() {
    if (this.currentWebinarIndex === this.amount - 1 || this.amount - 1 <= 0) {
      return false
    }
    return true
  }

  private get isPreviousButtonShow() {
    if (this.currentWebinarIndex === 0) {
      return false
    }
    return true
  }

}
