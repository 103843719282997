



















































import { Component, Prop } from 'vue-property-decorator'

// components
import Tag from '@/components/_uikit/Tag.vue'
// store
import { CourseType, DashboardManagerExerciseBlockResource } from '@/store/types'
import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import AuthModule from '@/store/modules/auth'
// import MentorMasterGroupMixin from '@/mixins/mentor/MentorMasterGroupMixin'

@Component({
  components: {
    ButtonArrow,
    Tag,
  },
})
export default class ManagerDashboardJournal extends MasterGroupMixin {
  @Prop({ default: () => ([]) })
  private exercises!: DashboardManagerExerciseBlockResource[]

  private get isCourseTypeSpecial() {
    return this.currentMasterGroup.course.type.value === CourseType.SPECIAL
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private determinateColor(exercise: DashboardManagerExerciseBlockResource) {
    const total = exercise.wait + exercise.onCheck + exercise.checked + exercise.completed
    const isDone = total === exercise.completed

    if (isDone) {
      return 'teal'
    } else if (exercise.isVerificationPassed) {
      return 'red'
    }
    return 'orange'
  }

  private to (exercise: DashboardManagerExerciseBlockResource) {
    if (this.isCourseTypeSpecial)
      return {
        name: 'manager.education.exercises.quickStart',
        params: {
          exerciseUUID: exercise.uuid,
          groupID: this.currentMasterGroupID,
        },
      }
    return {
      name: 'manager.education.exercises.item.masterList',
      params: {
        exerciseUUID: exercise.uuid,
        groupID: this.currentMasterGroupID,
        taskUUID: exercise.tasks[0].uuid,
      },
    }
  }
}
