























import { Component, Prop } from 'vue-property-decorator'

import MasterGroupsSearch from '@/components/_uikit/search/MasterGroupsSearch.vue'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import { DashboardMasterGroupBlockResource, SocialType } from '@/store/types'

@Component({
  components: {
    MasterGroupsSearch,
  },
})
export default class ManagerDashboardGroupSelection extends MasterGroupMixin {
  @Prop({ required: true })
  private groupData!: DashboardMasterGroupBlockResource

  @Prop({ default: false })
  private currentMasterGroupIsSpecial!: boolean

  private socialMediaChat = SocialType.TELEGRAM

  private get socialMediaIcon () {
    return require(`@/assets/images/social/${this.socialMediaChat}.png`)
  }
}
