












import { Component, Mixins, Watch } from 'vue-property-decorator'

// mixins
import CalendarMixin from '@/mixins/CalendarMixin'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
// store
import {
  MasterEducationMasterGroupIdCalendarGetParams,
} from '@/store/types'
import { formatDate } from '@/utils/functions'
import MasterDashboardModule from '@/store/modules/master/dashboard'

@Component
export default class DashboardCalendar extends Mixins(MasterGroupMixin, CalendarMixin) {
  private innerValue = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)

  private filter: MasterEducationMasterGroupIdCalendarGetParams = {
    endAt: '',
    startAt: '',
  }

  private get prolongationData() {
    if (this.isMaster && MasterDashboardModule.dashboard) {
      return MasterDashboardModule.dashboard.masterNeedNextMonthBlock
    }
    return null
  }

  private mounted() {
    const date = new Date()

    const startAt = new Date(date.getFullYear(), date.getMonth()).toLocaleDateString('ru-RU').split('.')
    const endAt = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString('ru-RU').split('.')

    this.filter = {
      endAt: `${endAt[2]}-${endAt[1]}-${endAt[0]}`,
      startAt: `${startAt[2]}-${startAt[1]}-${startAt[0]}`,
    }
  }

  private fetchCalendarData() {
    this.$bus.$emit('changeFilterMonth', this.filter.startAt.split('-')[1])
    this.fetchCalendar(this.currentMasterGroupID, this.filter)
      .catch(this.notifyError)
  }

  private formatWeek(week: string) {
    return formatDate(week, 'ccc').toUpperCase()
  }

  private handlePicker(date: string) {
    this.filter = this.handleUpdatePickerDate(date)
  }

  private handleClickDate(date: string) {
    this.$bus.$emit('filterDate', date)
  }

  @Watch('filter')
  watchFilter() {
    this.fetchCalendarData()
  }
}
