


















import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import ManagerDashboardLostLivesItem from '@/components/views/dashboard/manager/ManagerDashboardLostLivesItem.vue'
// store
import { DashboardLostHeartBlockResource } from '@/store/types'

@Component({ components: { ManagerDashboardLostLivesItem } })
export default class ManagerDashboardLostLives extends Vue {
  @Prop({ required: true })
  private lostHeartBlock!: DashboardLostHeartBlockResource[]
}
