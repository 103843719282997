






















import { Component, Prop } from 'vue-property-decorator'

// components
import UserSocialCard from '@/components/cards/UserSocialCard.vue'
import Hearts from '@/components/Hearts.vue'
// store
import { DashboardLostHeartBlockResource, ExerciseAccessLevel } from '@/store/types'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'

@Component({
  components: {
    Hearts,
    UserSocialCard,
  },
})
export default class ManagerDashboardLostLivesItem extends MasterGroupMixin {
  @Prop({ required: true })
  private lostLiveInformation!: DashboardLostHeartBlockResource

  private get isPersonal() {
    return this.lostLiveInformation.exercise.accessLevel.value === ExerciseAccessLevel.PERSONAL
  }
}
