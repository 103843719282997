










































import { groupBy, sortBy } from 'lodash'
import { Component, Mixins, Watch } from 'vue-property-decorator'

// types
import {
  CalendarLargeItemResource,
  ExerciseAccessLevel,
} from '@/store/types'
import Tag from '@/components/_uikit/Tag.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import CalendarMixin, { EventType } from '@/mixins/CalendarMixin'
import { formatDate, parseDateToMilliseconds } from '@/utils/functions'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import SystemModule from '@/store/modules/system'
import MasterEducationModule from '@/store/modules/master/education'

enum EventsTypes {
  LESSONS = 'lessons',
  EXERCISE = 'exercise',
  PERSONAL = 'personal',
  WEBINAR = 'webinar',
  MATERIAL = 'useful_material',
}

interface IEvent {
  accessAt?: string,
  date: string,
  id: number,
  isPassed: boolean,
  spendingAt: string,
  start?: string,
  taskUuid?: string,
  time: string,
  title: string,
  type: EventsTypes|EventsTypes[],
  typeEvent: EventType,
  uuid?: string,
  weekday: string,
  weekdayIndex: number,
}

@Component({
  components: {
    Tag,
  },
})
export default class DashboardSchedule extends Mixins(NotifyMixin, CalendarMixin, MasterGroupMixin) {
  private eventsOnList: IEvent[] = []
  private resultList: Array<IEvent[]> = []
  private activeFilter = 'all'
  private filterDate = ''
  private monthFilter = ''

  private widthScheduleList = 0
  private observer = new ResizeObserver(size => this.changeWidthScheduleList(size[0].contentRect.width))

  private get usefulMaterial() {
    return EventsTypes.MATERIAL
  }

  private mounted() {
    this.$nextTick(() => {
      this.widthScheduleList = (this.$refs.scheduleList as HTMLElement).clientWidth
    })

    this.observer.observe(this.$refs.scheduleList as Element)

    this.$bus.$on('filterDate', this.handleFilterDate)
    this.$bus.$on('changeFilterMonth', this.changeFilterMonth)
  }

  private beforeDestroy() {
    this.observer.unobserve(this.$refs.scheduleList as Element)
  }

  private destroyed() {
    this.$bus.$off('filterDate', this.handleFilterDate as any)
    this.$bus.$off('changeFilterMonth', this.changeFilterMonth as any)
  }

  private changeWidthScheduleList(width: number) {
    this.widthScheduleList = width
  }

  private handleInitEvents() {
    function _determinateTitle(exercise: CalendarLargeItemResource) {
      return exercise.title
    }

    if (this.calendar) {
      this.eventsOnList = []

      this.calendar.lesson.forEach(lesson => {
        // Из даты достается день, если имеется ноль, то отсекаем его для вывода.
        const lessonTime = formatDate(lesson.spendingAt, 'yyyy-MM-dd', this.isLocalTimezone)
        const lessonDate = lessonTime.split('-')
        const lessonToDisplay = {
          date: lessonDate[2][0] === '0' ? lessonDate[2].substring(1) : lessonDate[2],
          id: lesson.id,
          isPassed: lesson.isPassed,
          spendingAt: lesson.spendingAt,
          time: formatDate(lesson.spendingAt, 'HH:mm', this.isLocalTimezone),
          title: lesson.title,
          type: lesson.isWebinar ? EventsTypes.WEBINAR : EventsTypes.LESSONS,
          typeEvent: EventType.LESSON,
          weekday: this.weekLabelDecider(lesson.spendingAt),
          weekdayIndex: new Date(lesson.spendingAt).getDay() || 7,
        }

        this.eventsOnList.push(lessonToDisplay)
      })

      this.calendar.exercise.forEach(exercise => {
        const exerciseTime = formatDate(exercise.deadlineAt, 'yyyy-MM-dd', this.isLocalTimezone)
        const exerciseDate = exerciseTime.split('-')
        const exerciseToDisplay = {
          accessAt: exercise.spendingAt,
          date: exerciseDate[2][0] === '0' ? exerciseDate[2].substring(1) : exerciseDate[2],
          id: exercise.id,
          isPassed: exercise.isPassed,
          spendingAt: exercise.deadlineAt,
          taskUuid: exercise.tasks && exercise.tasks.length ? exercise.tasks[0].uuid : '',
          time: formatDate(exercise.deadlineAt, 'HH:mm', this.isLocalTimezone),
          title: exercise.tasks && exercise.tasks.length ? exercise.tasks.length > 1 ? 'Пробник' : exercise.tasks[0].type.name : `${_determinateTitle(exercise)} Нет заданий`,
          type: (exercise.exerciseAccessLevel as any).value === ExerciseAccessLevel.PERSONAL ? EventsTypes.PERSONAL : EventsTypes.EXERCISE,
          typeEvent: EventType.EXERCISE,
          uuid: exercise.uuid,
          weekday: this.weekLabelDecider(exercise.deadlineAt),
          weekdayIndex: new Date(exercise.deadlineAt).getDay() || 7,
        }
        if (exerciseDate[1] === this.monthFilter)
          this.eventsOnList.push(exerciseToDisplay)
      })

      this.calendar.usefulMaterial.forEach(material => {
        const materialDate = formatDate(material.spendingAt, 'yyyy-MM-dd HH:mm:ss', this.isLocalTimezone).substring(8, 10)
        const materialtoDisplay = {
          date: materialDate[0] === '0' ? materialDate[1] : materialDate,
          id: material.id,
          isPassed: material.isPassed,
          spendingAt: material.spendingAt.replace(/\d{2}:\d{2}:\d{2}/, '00:00:00'),
          start: material.spendingAt.replace(/\d{2}:\d{2}:\d{2}/, '00:00:00'),
          time: '',
          title: material.materialType || material.title,
          type: EventsTypes.MATERIAL,
          typeEvent: EventType.USEFUL_MATERIAL,
          weekday: this.weekLabelDecider(material.spendingAt),
          weekdayIndex: new Date(material.spendingAt).getDay() || 7,
        }
        this.eventsOnList.push(materialtoDisplay)
      })

      this.resultList = sortBy(groupBy(this.eventsOnList, 'date'), 'date').map((item: IEvent[]) => sortBy(item, 'time'))
    }
  }

  // Сюда приходят все события в отведенный день, для проверки к какому типу отнести и какие стили дать
  private eventClass(eventOnDay: IEvent[]) {
    let styles: string[] = [], baseClassName = 'dashboard-item-day-'

    eventOnDay.forEach(item => {
      if (item.type === EventsTypes.LESSONS && !styles.includes(baseClassName + EventsTypes.LESSONS)) {
        styles.push(baseClassName + EventsTypes.LESSONS)
      }
      if (item.type === EventsTypes.EXERCISE && !styles.includes(baseClassName + EventsTypes.EXERCISE)) {
        styles.push(baseClassName + EventsTypes.EXERCISE)
      }
      if (item.type === EventsTypes.PERSONAL && !styles.includes(baseClassName + EventsTypes.PERSONAL)) {
        styles.push(baseClassName + EventsTypes.PERSONAL)
      }
      if (item.type === EventsTypes.WEBINAR && !styles.includes(baseClassName + EventsTypes.WEBINAR)) {
        styles.push(baseClassName + EventsTypes.WEBINAR)
      }
      if (item.type === EventsTypes.MATERIAL && !styles.includes(baseClassName + EventsTypes.MATERIAL)) {
        styles.push(baseClassName + EventsTypes.MATERIAL)
      }
    })

    return styles
  }

  private handleFilter(value: string) {
    this.activeFilter = value
  }

  private changeFilterMonth(month: string) {
    this.monthFilter = month
  }

  private handleFilterDate(date: string) {
    this.activeFilter = 'date'
    this.filterDate = formatDate(date, 'd MMM yyyy')
  }

  private toRoute(event: any) {
    const { typeEvent } = event
    const accessAt = event.accessAt ? parseDateToMilliseconds(event.accessAt) : null
    const dateRaw = parseDateToMilliseconds(this.dateRaw)

    if (typeEvent === EventType.LESSON) {
      if (SystemModule.interface === 'manager') {
        return { name: 'manager.education.lessons.item', params: { groupID: (this.currentMasterGroupID as number).toString(), lessonID: event.id } }
      } else {
        return { name: 'master.lessons.item', params: { groupID: MasterEducationModule.currentMasterGroupID.toString(), lessonID: event.id } }
      }
    }

    if (typeEvent === EventType.EXERCISE) {
      if (SystemModule.interface === 'manager') {
        if (event.taskUuid)
          return { name: 'manager.education.exercises.item.masterList', params: { exerciseUUID: event.uuid, groupID: (this.currentMasterGroupID as number).toString(), taskUUID: event.taskUuid } }
        else
          return { name: 'manager.education.exercises.item.edit', params: { exerciseUUID: event.uuid, groupID: this.currentMasterGroupID } }
      } else if (SystemModule.interface === 'master' && accessAt && accessAt > dateRaw) {
        return undefined
      } else {
        return { name: 'master.exercises.item', params: { exerciseUUID: event.uuid, groupID: this.currentMasterGroupID } }
      }
    }

    if (typeEvent === EventType.USEFUL_MATERIAL) {
      if (SystemModule.interface === 'master') {
        return { name: 'master.depository.item.materials', params: { courseID: this.currentMasterGroup.course.id.toString() }, query: { endDate: formatDate(event.start, 'yyyy-MM-dd'), startDate: formatDate(event.start, 'yyyy-MM-dd') } }
      } else {
        return { name: 'manager.bank.depository.courses.item.materials.view', params: { courseID: this.currentMasterGroup.course.id.toString(), materialID: event.id.toString() } }
      }
    }

    return undefined
  }

  private get options() {
    const baseOptions = [
      { name: 'Все', value: 'all' },
      { name: 'Урок', value: EventsTypes.LESSONS },
      { name: 'Задание', value: EventsTypes.EXERCISE },
      { name: 'Полезное', value: EventsTypes.MATERIAL },
    ]

    /*if (this.currentMasterGroupIsPlus) {
      baseOptions.splice(3, 0, { name: 'Урок с наставником', value: EventsTypes.WEBINAR })
      baseOptions.splice( 2, 0, { name: 'Индивидуально', value: EventsTypes.PERSONAL })
    }*/
    return baseOptions
  }

  private colorEvent(type: EventsTypes) {
    switch (type) {
    case 'exercise':
      return 'orange desaturate-2'
    case 'useful_material':
      return 'blue lighten-2'
    case 'lessons':
      return 'teal lighten-2'
    case 'webinar':
      return 'teal lighten-2'
    case 'personal':
      return 'orange desaturate-2'
    default:
      return 'orange'
    }
  }

  @Watch('calendar', { deep: true })
  watchCalendarChange() {
    this.activeFilter = 'all'
    this.handleInitEvents()
  }

  @Watch('activeFilter')
  watchActiveFilter(value: EventsTypes | 'all' | 'date') {
    const initialList: Array<IEvent[]> = sortBy(groupBy(this.eventsOnList, 'date'), 'date').map((item: IEvent[]) => sortBy(item, 'time'))

    if (value === 'date')
      return

    this.filterDate = ''
    if (value === 'all') {
      this.resultList = initialList
    } else {
      this.resultList = initialList.map(item => {
        return item.filter(item => item.type === value)
      }).filter(item => item.length)
    }
  }

  @Watch('filterDate')
  private watchFilterDate(value: string) {
    if (value) {
      const initialList: Array<IEvent[]> = sortBy(groupBy(this.eventsOnList, 'date'), 'date').map((item: IEvent[]) => sortBy(item, 'time'))
      const day = value.split(' ')[0]

      this.resultList = initialList.map(item => {
        return item.filter(item => item.date === day)
      }).filter(item => item.length)
    }
  }
}
