































import { Component, Mixins, Prop } from 'vue-property-decorator'

import CoursesSettings from '@/components/CoursesSettings.vue'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import AuthModule from '@/store/modules/auth'
import { SelfResource } from '@/store/types'

@Component({
  components: {
    CoursesSettings,
  },
})
export default class WelcomeCard extends Mixins(PermissionsMixin) {
  @Prop({ default: '' })
  private buttonLoginAsText!: string

  @Prop({ default: '' })
  private buttonRedirectTo!: string

  private get user () {
    return AuthModule.self as SelfResource
  }
}
