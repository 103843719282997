













































import { MetaInfo } from 'vue-meta'
import { Component, Mixins, Watch } from 'vue-property-decorator'

// mixins
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
// components
import DashboardCalendar from '@/components/views/dashboard/DashboardCalendar.vue'
import DashboardMentor from '@/components/views/dashboard/DashboardMentor.vue'
import DashboardSchedule from '@/components/views/dashboard/DashboardSchedule.vue'
import ManagerDashboardLostLives from '@/components/views/dashboard/manager/ManagerDashboardLostLives.vue'
import ManagerDashboardJournal from '@/components/views/dashboard/manager/ManagerDashboardJournal.vue'
import ManagerDashboardGroupSelection from '@/components/views/dashboard/manager/ManagerDashboardGroupSelection.vue'
import WelcomeCard from '@/components/cards/WelcomeCard.vue'
// store
import ManagerDashboardModule from '@/store/modules/manager/dashboard'
import ManagerDashboardWebinar from '@/components/views/dashboard/manager/ManagerDashboardWebinar.vue'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    DashboardCalendar,
    DashboardMentor,
    DashboardSchedule,
    ManagerDashboardGroupSelection,
    ManagerDashboardJournal,
    ManagerDashboardLostLives,
    ManagerDashboardWebinar,
    WelcomeCard,
  },
})
export default class ManagerDashboard extends Mixins(MasterGroupMixin, PermissionsMixin) {
  private isLoaded = false // Note: Schedule необходимо размаунтить для получения новых данных
  private isWelcomeState = false

  private handleRedirectToMyGroups() {
    this.$router.push({ name: 'manager.education.groups' })
  }

  private handleLoginAs() {
    this.$router.push('/master')
  }

  private get dashboard() {
    return ManagerDashboardModule.dashboard
  }

  private get exercises() {
    if (this.dashboard)
      return this.dashboard.managerExerciseBlock.filter(exercise => exercise.tasks.length)
    return []
  }

  private get webinars() {
    if (this.dashboard && this.dashboard.managerLessonBlock) {
      return this.dashboard.managerLessonBlock.filter(item => item.isWebinar)
    }
    return []
  }

  private get showDashboard() {
    return this.dashboard && this.isLoaded
  }

  private get selfId() {
    if (AuthModule.self) {
      return AuthModule.self.id
    }
    return null
  }

  private get isMentorForCurrentGroup() {
    if (this.dashboard && this.dashboard.masterGroupBlock?.mentor) {
      return this.dashboard.masterGroupBlock.mentor.id === this.selfId && !this.dashboard.masterGroupBlock.seniors.length
    }
    return false
  }

  private get isSeniorForCurrentGroup() {
    if (this.dashboard && this.dashboard.masterGroupBlock?.seniors.length) {
      return !!this.dashboard.masterGroupBlock.seniors.find(senior => senior.id === this.selfId)
    }
    return false
  }

  private mounted() {
    if (this.masterGroups.length === 0) {
      this.isWelcomeState = true
    }
    else {
      this.fetchDashboard()
    }
  }

  private fetchDashboard () {
    this.isLoaded = false
    ManagerDashboardModule.fetchDashboard(this.currentMasterGroupID)
      .catch((error) => {
        if (error.response.status === 404) {
          this.notifyError('Курс не найден. Перезагрузите страницу')
        } else {
          this.notifyError(error)
        }
      })
      .finally(() => this.isLoaded = true)
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Дашборд',
    }
  }

  @Watch('currentMasterGroupID')
  watchCurrentMasterGroupID() {
    this.fetchDashboard()
  }
}
