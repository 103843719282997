

























import { Component, Prop, Vue } from 'vue-property-decorator'

import UserCard from '@/components/cards/UserCard.vue'
import { DashboardMasterGroupBlockResource, SocialType } from '@/store/types'

@Component({
  components: {
    UserCard,
  },
})
export default class DashboardMentor extends Vue {
  @Prop({ required: true })
  private groupData!: DashboardMasterGroupBlockResource

  @Prop({ required: false })
  private isMaster!: boolean

  @Prop({ default: false })
  private isMentorForCurrentGroup!: boolean

  private socialMediaChat = SocialType.TELEGRAM

  private get socialMediaIcon() {
    return require(`@/assets/images/social/${this.socialMediaChat}.png`)
  }

  private get mentor () {
    return this.groupData.mentor
  }

  private get seniors () {
    return this.groupData.seniors
  }
}
